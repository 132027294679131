import { DEFAULT_FLAGS, OrgId } from "@hex/common";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import { GetHexFlagFn } from "../util/useHexFlags.js";

import { rootSaga } from "./sagas";
import { appSessionMPReducer } from "./slices/appSessionMPSlice";
import { appViewReducer } from "./slices/appViewSlice.js";
import { browserStorageReducer } from "./slices/browserStorageSlice.js";
import { cellGraphReducer } from "./slices/cellGraphSlice";
import { currentUserReducer } from "./slices/currentUserSlice.js";
import { dataBrowserTreeReducer } from "./slices/dataBrowserSlice.js";
import { hexMPReducer } from "./slices/hexMPSlice.js";
import { hexSelectionReducer } from "./slices/hexSelectionSlice.js";
import { hexVersionMPReducer } from "./slices/hexVersionMPSlice";
import { lockReducer } from "./slices/lockSlice.js";
import { logicViewReducer } from "./slices/logicViewSlice";
import { mapDatasetReducer } from "./slices/mapDatasetSlice";
import { mpDebuggerReducer } from "./slices/mpDebuggerSlice.js";
import { projectGraphV3Reducer } from "./slices/projectGraphV3Slice";
import { reviewRequestMPReducer } from "./slices/reviewRequestMPSlice.js";
import { savedViewDefaultAppSessionReducer } from "./slices/savedViewDefaultAppSessionSlice.js";
import { schemaTreeBrowserReducer } from "./slices/schemaTreeBrowserSlice";
import { getSubscribeOnChangeEnhancer } from "./subscribeOnChange";

interface StoreOptions {
  getHexFlag?: GetHexFlagFn;
  disableSagas?: boolean;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const createStore = (
  orgId: OrgId,
  {
    disableSagas = false,
    getHexFlag = (flag) => DEFAULT_FLAGS[flag],
  }: StoreOptions = {},
) => {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: {
      appSessionMP: appSessionMPReducer,
      savedViewDefaultAppSession: savedViewDefaultAppSessionReducer,
      appView: appViewReducer,
      browserStorage: browserStorageReducer,
      hexMP: hexMPReducer,
      hexVersionMP: hexVersionMPReducer,
      currentUser: currentUserReducer,
      logicView: logicViewReducer,
      cellGraph: cellGraphReducer,
      projectGraphV3: projectGraphV3Reducer,
      mapDataset: mapDatasetReducer,
      schemaTreeBrowser: schemaTreeBrowserReducer,
      dataBrowser: dataBrowserTreeReducer,
      reviewRequestMP: reviewRequestMPReducer,
      mpDebugger: mpDebuggerReducer,
      lock: lockReducer,
      hexSelection: hexSelectionReducer,
    },
    enhancers: [getSubscribeOnChangeEnhancer()],
    middleware: disableSagas ? [] : [sagaMiddleware],
    devTools: {
      serialize: {
        options: {
          map: true,
        },
      },
    },
  });
  if (!disableSagas) {
    sagaMiddleware.run(rootSaga, orgId, getHexFlag);
  }
  return store;
};

export type AppStore = ReturnType<typeof createStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
