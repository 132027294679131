import { typedObjectEntries } from "@hex/common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { GetLocksForHexVersionQuery } from "../../hooks/locks/useLockUpdates.generated.js";

export type Lock = GetLocksForHexVersionQuery["locksForHexVersion"][0];

type LockSliceState = {
  [componentId: string]: Lock;
};

const initialState: LockSliceState = {};

const LockSlice = createSlice({
  name: "Lock",
  initialState,
  reducers: {
    setLocks: (
      state,
      action: PayloadAction<{ [componentId: string]: Lock }>,
    ) => {
      typedObjectEntries(action.payload).forEach(([componentId, lock]) => {
        state[componentId] = lock;
      });
    },
    setSingleLock: (
      state,
      action: PayloadAction<{ componentId: string; lock: Lock }>,
    ) => {
      state[action.payload.componentId] = action.payload.lock;
    },
  },
});

export const lockReducer = LockSlice.reducer;
export const { setLocks, setSingleLock } = LockSlice.actions;

export const selectLocks = (state: { lock: LockSliceState }): LockSliceState =>
  state.lock;
