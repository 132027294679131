import { Tree, TreeNodeInfo } from "@blueprintjs/core";
import {
  Cursor,
  DataConnectionId,
  DataSourceColumnId,
  HexId,
  assertNever,
  typedObjectKeys,
} from "@hex/common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  MinimalCategory,
  MinimalStatus,
} from "../../hooks/useProjectLabelsForHex.js";

export type SearchResultType = "objects" | "columns";
export type NodeType = "table" | "object";

export interface NodeMetadata {
  cursor?: Cursor | null;
  /** Pinned state will only show up for DATA_BROWSER */
  pinned: boolean;
  dummy?: boolean;
  description?: string;
  status?: MinimalStatus | null;
  categories?: MinimalCategory[] | null;
}
export interface SelectedNodeInfo {
  type: NodeType;
  id: string | number;
  path?: NodePath;
}

export interface SelectedDataframeColumnSearchResultInfo {
  df: string;
  column: string;
}

const SPLIT_TREE_HEIGHT = "50%";

export type BrowserState = {
  [dataConnectionId: DataConnectionId]: {
    browserNodes: TreeNodeInfo<NodeMetadata>[];
    searchNodes: TreeNodeInfo<NodeMetadata>[];
    selectedBrowserNode: (SelectedNodeInfo & { idPath?: string[] }) | undefined;
    selectedSearchNode: SelectedNodeInfo | undefined;
    selectedColumnId: DataSourceColumnId | undefined;
    selectedColumnSearchResult: DataSourceColumnId | undefined;
    searchInput: string;
    searchActive: boolean;
    searchResultType: SearchResultType;
    showPinnedOnly: boolean;
    panelOpen: boolean;
    dataLoaded: boolean;
    autoFocusSearch: boolean;
    /**
     * This gets set to true after the nodes are rendered in the schema tree for
     * the first time.
     */
    nodesAutoExpanded: boolean;
  };
};

type SchemaTreeBrowserState = {
  panelHeight: string;
  dataConnections: BrowserState;

  dataframes: {
    [hexId: HexId]: {
      selectedDataframe: string | number | undefined;
      searchInput: string;
      searchResultType: SearchResultType;
      panelOpen: boolean;
      selectedColumnSearchResult:
        | SelectedDataframeColumnSearchResultInfo
        | undefined;
    };
  };
};
const initialSchemaTreeBrowserState: SchemaTreeBrowserState = {
  panelHeight: SPLIT_TREE_HEIGHT,
  dataConnections: {},
  dataframes: {},
};

export type NodePath = number[];

export type TreeType = "browser" | "search";

type ResetStatePayload = {
  dataConnectionId: DataConnectionId;
  nodes: TreeNodeInfo<NodeMetadata>[];
  preserveSelectedBrowserNode: boolean;
};

type SetShowPinnedOnlyPayload = {
  dataConnectionId: DataConnectionId;
  pinnedOnly: boolean;
};

type SetPanelOpenPayload = {
  dataConnectionId: DataConnectionId;
  panelOpen: boolean;
};

/**
 * This is only used for type DATA_BROWSER
 */
type SetNodePinnedPayload = {
  dataConnectionId: DataConnectionId;
  idPath: (string | number)[];
  pinned: boolean;
  multiDatabase: boolean;
  sideEffects?: boolean;
  kind?: NodeKind;
};

export type NodeKind = "schema" | "database" | "table";

type SetNodeStatusPayload = {
  dataConnectionId: DataConnectionId;
  idPath: (string | number)[];
  status: MinimalStatus | null;
  kind?: NodeKind;
};

type SetNodeCategoriesPayload = {
  dataConnectionId: DataConnectionId;
  idPath: (string | number)[];
  category: MinimalCategory;
  added: boolean;
  kind?: NodeKind;
};

type SetSearchResultsPayload = {
  dataConnectionId: DataConnectionId;
  searchResults: TreeNodeInfo<NodeMetadata>[];
};

type ClearSearchPayload = {
  dataConnectionId: DataConnectionId;
};

type SetSearchActivePayload = {
  dataConnectionId: DataConnectionId;
  searchActive: boolean;
  showPinnedOnly?: boolean;
};

type SetSearchResultTypePayload = {
  dataConnectionId: DataConnectionId;
  resultType: SearchResultType;
};

type CollapseAllNodesPayload = {
  dataConnectionId: DataConnectionId;
  treeType: TreeType;
};

type FocusNodeByPathPayload = {
  dataConnectionId: DataConnectionId;
  path: NodePath;
};

type SetSearchInputPayload = {
  dataConnectionId: DataConnectionId;
  searchInput: string;
};

type SetNodeSelectedPayload = {
  nodeId: string | number;
  isSelected: boolean;
  dataConnectionId: DataConnectionId;
  treeType: TreeType;
  nodeType: NodeType;
  path?: NodePath;
  /**
   * This is only relevant for selected browser node. If provided, the schema
   * tree will expand all nodes on this path, and collapse all others.
   */
  idPath?: string[];
};

type SetAllNodesUnselectedPayload = {
  /**
   * If undefined, unselects nodes for all data connections
   */
  dataConnectionId?: DataConnectionId;
  treeType: TreeType;
  collapseAllNodes?: boolean;
};

type SetSelectedColumnPayload = {
  dataConnectionId: DataConnectionId;
  columnId: DataSourceColumnId | undefined;
};

type SetSelectedColumnSearchResultPayload = {
  dataConnectionId: DataConnectionId;
  columnId: DataSourceColumnId | undefined;
};

type SetNodeExpandedPayload = {
  path: NodePath;
  isExpanded: boolean;
  dataConnectionId: DataConnectionId;
  treeType: TreeType;
};

type ExpandNodesAtPath = {
  path: NodePath;
  dataConnectionId: DataConnectionId;
  treeType: TreeType;
};

type SetChildrenPayload = {
  dataConnectionId: DataConnectionId;
  path: NodePath;
  children: TreeNodeInfo<NodeMetadata>[];
};

type SetSelectedDataframePayload = {
  hexId: HexId;
  dataframeName: string | number | undefined;
};

type SetDataframeSearchInputPayload = {
  hexId: HexId;
  searchInput: string;
};

type SetDataframeSearchResultTypePayload = {
  hexId: HexId;
  resultType: SearchResultType;
};

type SetDataframePanelOpenPayload = {
  hexId: HexId;
  panelOpen: boolean;
};

type SetDataframeSelectedColumnSearchResultPayload = {
  hexId: HexId;
  column: string;
  df: string;
};

type SetPanelHeightPayload = {
  panelHeight: string;
};

type SetAutoFocusSearchPayload = {
  dataConnectionId: DataConnectionId;
  autoFocusSearch: boolean;
};

export function isDatabase(
  idPath: (string | number)[],
  multiDatabase: boolean,
): boolean {
  return idPath.length === 1 && multiDatabase;
}

function forEachNode(
  nodes: TreeNodeInfo<NodeMetadata>[] | undefined,
  callback: (node: TreeNodeInfo<NodeMetadata>) => void,
): void {
  if (nodes == null) {
    return;
  }
  for (const node of nodes) {
    callback(node);
    forEachNode(node.childNodes, callback);
  }
}

function forNodeAtPath(
  nodes: TreeNodeInfo<NodeMetadata>[],
  path: NodePath,
  callback: (node: TreeNodeInfo<NodeMetadata>) => void,
): void {
  callback(Tree.nodeFromPath(path, nodes));
}

export function nodePathFromNodeIdPath(
  nodes: TreeNodeInfo<NodeMetadata>[],
  nodeIdPath: (string | number)[],
): NodePath | undefined {
  const nodePath: NodePath = [];
  let currentNodes = nodes;
  for (const id of nodeIdPath) {
    const nodeIdx = currentNodes.findIndex((n) => n.id === id);
    if (nodeIdx < 0) {
      return undefined;
    }
    nodePath.push(nodeIdx);
    currentNodes = currentNodes[nodeIdx]?.childNodes ?? [];
  }
  return nodePath;
}

function findNodeInPath({
  idPath,
  kind,
  nodes,
}: {
  kind?: NodeKind;
  nodes: TreeNodeInfo<NodeMetadata>[];
  idPath: (string | number)[];
}): TreeNodeInfo<NodeMetadata> | undefined {
  let node;
  if (kind === "table") {
    let parentNode = nodes.find((n) => n.id === idPath[0]);
    if (idPath.length === 3) {
      parentNode = parentNode?.childNodes?.find((n) => n.id === idPath[1]);
    }
    node = parentNode?.childNodes?.find(
      (n) => n.id === idPath[idPath.length - 1],
    );
  } else {
    node =
      idPath.length === 2
        ? nodes
            .find((n) => n.id === idPath[0])
            ?.childNodes?.find((n) => n.id === idPath[1])
        : nodes.find((n) => n.id === idPath[0]);
  }
  return node;
}

const schemaTreeBrowserSlice = createSlice({
  name: "schemaTreeBrowser",
  initialState: initialSchemaTreeBrowserState,
  reducers: {
    resetSchemaBrowserState: (
      state,
      action: PayloadAction<ResetStatePayload>,
    ) => {
      const { dataConnectionId, nodes, preserveSelectedBrowserNode } =
        action.payload;
      state.dataConnections[dataConnectionId] = {
        browserNodes: nodes,
        searchNodes: [],
        selectedBrowserNode: preserveSelectedBrowserNode
          ? state.dataConnections[dataConnectionId]?.selectedBrowserNode
          : undefined,
        selectedSearchNode: undefined,
        selectedColumnId: undefined,
        selectedColumnSearchResult: undefined,
        searchInput: "",
        searchActive: false,
        searchResultType: "objects",
        showPinnedOnly: false,
        panelOpen: false,
        dataLoaded: true,
        autoFocusSearch: false,
        nodesAutoExpanded: false,
      };
    },
    clearSchemaBrowserState: (
      state,
      action: PayloadAction<DataConnectionId>,
    ) => {
      delete state.dataConnections[action.payload];
    },
    setNodePinned: (state, action: PayloadAction<SetNodePinnedPayload>) => {
      const {
        dataConnectionId,
        idPath,
        kind,
        multiDatabase,
        pinned,
        sideEffects,
      } = action.payload;

      const nodes = state.dataConnections[dataConnectionId]?.browserNodes;
      if (!nodes) {
        return;
      }
      const node = findNodeInPath({
        kind,
        nodes,
        idPath,
      });
      if (!node) {
        return;
      }
      node.nodeData = {
        pinned,
        cursor: node.nodeData?.cursor,
        status: node.nodeData?.status,
        categories: node.nodeData?.categories,
      };

      if (kind !== "table" && multiDatabase && sideEffects) {
        // if (un)pinning a database, also (un)pin all its child schemas
        if (idPath.length === 1) {
          node.childNodes?.forEach((n) => {
            n.nodeData = {
              pinned,
              cursor: n.nodeData?.cursor,
              status: n.nodeData?.status,
              categories: n.nodeData?.categories,
            };
          });
        }
        // if unpinning the only pinned schema in a database, also unpin the database
        if (idPath.length === 2 && !pinned) {
          const databaseNode = nodes.find((n) => n.id === idPath[0]);
          if (
            databaseNode &&
            databaseNode.childNodes?.every((n) => !n.nodeData?.pinned)
          ) {
            databaseNode.nodeData = {
              pinned: false,
              cursor: databaseNode.nodeData?.cursor,
              status: databaseNode.nodeData?.status,
              categories: databaseNode.nodeData?.categories,
            };
          }
        }
        // if pinning a schema, also pin the parent database
        if (idPath.length === 2 && pinned) {
          const databaseNode = nodes.find((n) => n.id === idPath[0]);
          if (databaseNode) {
            databaseNode.nodeData = {
              pinned: true,
              cursor: databaseNode.nodeData?.cursor,
              status: databaseNode.nodeData?.status,
              categories: databaseNode.nodeData?.categories,
            };
          }
        }
      }
    },
    setNodeStatus: (state, action: PayloadAction<SetNodeStatusPayload>) => {
      const { dataConnectionId, idPath, kind, status } = action.payload;

      const nodes = state.dataConnections[dataConnectionId]?.browserNodes;
      if (!nodes) {
        return;
      }
      const node = findNodeInPath({
        kind,
        nodes,
        idPath,
      });
      if (!node) {
        return;
      }
      node.nodeData = {
        pinned: node.nodeData?.pinned ?? false,
        cursor: node.nodeData?.cursor,
        categories: node.nodeData?.categories,
        status,
      };
    },
    setNodeCategories: (
      state,
      action: PayloadAction<SetNodeCategoriesPayload>,
    ) => {
      const { added, category, dataConnectionId, idPath, kind } =
        action.payload;
      const nodes = state.dataConnections[dataConnectionId]?.browserNodes;
      if (!nodes) {
        return;
      }
      const node = findNodeInPath({
        kind,
        nodes,
        idPath,
      });
      if (!node) {
        return;
      }
      const prevCategories = node.nodeData?.categories ?? [];
      const newCategories = added
        ? [...prevCategories, category]
        : prevCategories.filter((c) => c.id !== category.id);
      node.nodeData = {
        pinned: node.nodeData?.pinned ?? false,
        cursor: node.nodeData?.cursor,
        status: node.nodeData?.status,
        categories: newCategories,
      };
    },
    setSearchResults: (
      state,
      action: PayloadAction<SetSearchResultsPayload>,
    ) => {
      const { dataConnectionId, searchResults } = action.payload;

      // We want to reset the selected search node when search results change
      const selectedSearchNode =
        state.dataConnections[dataConnectionId].selectedSearchNode;
      if (selectedSearchNode && selectedSearchNode.path) {
        let nodeAtPath;
        try {
          // Tree.nodeFromPath will throw an error if there is no node at the specified path
          nodeAtPath = selectedSearchNode.path
            ? Tree.nodeFromPath(selectedSearchNode.path, searchResults)
            : undefined;
          // eslint-disable-next-line no-restricted-syntax -- we just rest the state if this errors
        } catch {
          // we reset the selected search node state below
        }
        if (nodeAtPath?.id !== selectedSearchNode.id) {
          state.dataConnections[dataConnectionId].selectedSearchNode =
            undefined;
        }
      }
      state.dataConnections[dataConnectionId].searchNodes = searchResults;
    },
    collapseAllNodes: (
      state,
      action: PayloadAction<CollapseAllNodesPayload>,
    ) => {
      const { dataConnectionId, treeType } = action.payload;
      const nodes =
        treeType === "search"
          ? state.dataConnections[dataConnectionId]?.searchNodes
          : state.dataConnections[dataConnectionId]?.browserNodes;
      forEachNode(nodes ?? [], (node) => {
        node.isExpanded = false;
      });
    },
    focusNode: (state, action: PayloadAction<FocusNodeByPathPayload>) => {
      const { dataConnectionId, path } = action.payload;
      for (let i = 1; i <= path.length; i++) {
        forNodeAtPath(
          state.dataConnections[dataConnectionId].browserNodes,
          path.slice(0, i),
          (node) => (node.isExpanded = true),
        );
      }
    },
    setSearchInput: (state, action: PayloadAction<SetSearchInputPayload>) => {
      const { dataConnectionId, searchInput } = action.payload;
      if (!state.dataConnections[dataConnectionId]) {
        state.dataConnections[dataConnectionId] = {
          browserNodes: [],
          searchNodes: [],
          selectedBrowserNode: undefined,
          selectedSearchNode: undefined,
          selectedColumnId: undefined,
          selectedColumnSearchResult: undefined,
          searchInput,
          searchActive: false,
          searchResultType: "objects",
          showPinnedOnly: false,
          panelOpen: false,
          dataLoaded: false,
          autoFocusSearch: false,
          nodesAutoExpanded: false,
        };
      } else {
        state.dataConnections[dataConnectionId].searchInput = searchInput;
      }
    },
    setShowPinnedOnly: (
      state,
      action: PayloadAction<SetShowPinnedOnlyPayload>,
    ) => {
      const { dataConnectionId, pinnedOnly } = action.payload;
      state.dataConnections[dataConnectionId].showPinnedOnly = pinnedOnly;
    },
    setPanelOpen: (state, action: PayloadAction<SetPanelOpenPayload>) => {
      const { dataConnectionId, panelOpen } = action.payload;
      state.dataConnections[dataConnectionId].panelOpen = panelOpen;
    },
    clearSearch: (state, action: PayloadAction<ClearSearchPayload>) => {
      const { dataConnectionId } = action.payload;
      if (state.dataConnections[dataConnectionId]) {
        state.dataConnections[dataConnectionId].searchActive = false;
        state.dataConnections[dataConnectionId].searchInput = "";
        state.dataConnections[dataConnectionId].selectedSearchNode = undefined;
        state.dataConnections[dataConnectionId].searchNodes = [];
        state.dataConnections[dataConnectionId].selectedColumnSearchResult =
          undefined;
      }
    },
    setSearchActive: (state, action: PayloadAction<SetSearchActivePayload>) => {
      const { dataConnectionId, searchActive, showPinnedOnly } = action.payload;

      if (!state.dataConnections[dataConnectionId]) {
        state.dataConnections[dataConnectionId] = {
          browserNodes: [],
          searchNodes: [],
          selectedBrowserNode: undefined,
          selectedSearchNode: undefined,
          selectedColumnId: undefined,
          selectedColumnSearchResult: undefined,
          searchInput: "",
          searchActive,
          searchResultType: "objects",
          showPinnedOnly: showPinnedOnly ?? true,
          panelOpen: false,
          dataLoaded: false,
          autoFocusSearch: false,
          nodesAutoExpanded: false,
        };
      } else {
        state.dataConnections[dataConnectionId].searchActive = searchActive;
      }
    },
    setSearchResultType: (
      state,
      action: PayloadAction<SetSearchResultTypePayload>,
    ) => {
      const { dataConnectionId, resultType } = action.payload;
      state.dataConnections[dataConnectionId].searchResultType = resultType;
    },
    /**
     * In some cases we specifically want to unselect all nodes so we don't
     * want to use setNodeSelected which expects a specific node.
     */
    setNodesUnselected: (
      state,
      action: PayloadAction<SetAllNodesUnselectedPayload>,
    ) => {
      const { dataConnectionId, treeType } = action.payload;
      const dataConnectionIds = dataConnectionId
        ? [dataConnectionId]
        : typedObjectKeys(state.dataConnections);

      dataConnectionIds.forEach((dcId) => {
        const nodes =
          treeType === "search"
            ? state.dataConnections[dcId].searchNodes
            : state.dataConnections[dcId].browserNodes;
        forEachNode(nodes, (node) => {
          node.isSelected = false;
          node.isExpanded = false;
        });
        if (treeType === "search") {
          state.dataConnections[dcId].selectedSearchNode = undefined;
        }
        if (treeType === "browser") {
          state.dataConnections[dcId].selectedBrowserNode = undefined;
        }
      });
    },
    setNodeSelected: (state, action: PayloadAction<SetNodeSelectedPayload>) => {
      const {
        dataConnectionId,
        idPath,
        isSelected,
        nodeId,
        nodeType,
        path,
        treeType,
      } = action.payload;

      if (treeType === "browser") {
        if (state.dataConnections[dataConnectionId] == null) {
          state.dataConnections[dataConnectionId] = {
            browserNodes: [],
            searchNodes: [],
            selectedBrowserNode: undefined,
            selectedSearchNode: undefined,
            selectedColumnId: undefined,
            selectedColumnSearchResult: undefined,
            searchInput: "",
            searchActive: false,
            searchResultType: "objects",
            showPinnedOnly: false,
            panelOpen: false,
            dataLoaded: false,
            autoFocusSearch: false,
            nodesAutoExpanded: false,
          };
        }
        state.dataConnections[dataConnectionId].selectedBrowserNode = isSelected
          ? { id: nodeId, type: nodeType, path, idPath }
          : undefined;
      } else if (treeType === "search") {
        state.dataConnections[dataConnectionId].selectedSearchNode = isSelected
          ? { id: nodeId, type: nodeType, path }
          : undefined;
      } else {
        assertNever(treeType, treeType);
      }
      state.dataConnections[dataConnectionId].selectedColumnId = undefined;
      const nodes =
        treeType === "search"
          ? state.dataConnections[dataConnectionId].searchNodes
          : state.dataConnections[dataConnectionId].browserNodes;
      forEachNode(nodes, (node) =>
        node.id === nodeId
          ? (node.isSelected = isSelected)
          : (node.isSelected = false),
      );
    },
    // We only dynamically set the children of nodes in the main tree, not search results
    setChildren: (state, action: PayloadAction<SetChildrenPayload>) => {
      const { children, dataConnectionId, path } = action.payload;
      forNodeAtPath(
        state.dataConnections[dataConnectionId].browserNodes,
        path,
        (node) => (node.childNodes = children),
      );
    },
    /**
     * Use @expandNodesAtPath if you want to expand all nodes along the path
     */
    setNodeExpanded: (state, action: PayloadAction<SetNodeExpandedPayload>) => {
      const { dataConnectionId, isExpanded, path, treeType } = action.payload;
      const nodes =
        treeType === "search"
          ? state.dataConnections[dataConnectionId].searchNodes
          : state.dataConnections[dataConnectionId].browserNodes;
      forNodeAtPath(nodes, path, (node) => (node.isExpanded = isExpanded));
    },
    /**
     * Unlike @setNodeExpanded which expands the specific node specified by the path, this expands all nodes along the given path
     */
    expandNodesAtPath: (state, action: PayloadAction<ExpandNodesAtPath>) => {
      const { dataConnectionId, path, treeType } = action.payload;
      const nodes =
        treeType === "search"
          ? state.dataConnections[dataConnectionId].searchNodes
          : state.dataConnections[dataConnectionId].browserNodes;

      for (let i = 1; i <= path.length; i++) {
        forNodeAtPath(
          nodes,
          path.slice(0, i),
          (node) => (node.isExpanded = true),
        );
      }
    },
    setSelectedColumnId: (
      state,
      action: PayloadAction<SetSelectedColumnPayload>,
    ) => {
      const { columnId, dataConnectionId } = action.payload;

      state.dataConnections[dataConnectionId].selectedColumnId = columnId;
    },
    setSelectedColumnSearchResult: (
      state,
      action: PayloadAction<SetSelectedColumnSearchResultPayload>,
    ) => {
      const { columnId, dataConnectionId } = action.payload;
      state.dataConnections[dataConnectionId].selectedColumnSearchResult =
        columnId;
    },
    setSelectedDataframe: (
      state,
      action: PayloadAction<SetSelectedDataframePayload>,
    ) => {
      const { dataframeName, hexId } = action.payload;
      if (!state.dataframes[hexId]) {
        state.dataframes[hexId] = {
          selectedDataframe: dataframeName,
          searchInput: "",
          searchResultType: "objects",
          panelOpen: false,
          selectedColumnSearchResult: undefined,
        };
      } else {
        state.dataframes[hexId].selectedDataframe = dataframeName;
      }
    },
    setDataframeSearchInput: (
      state,
      action: PayloadAction<SetDataframeSearchInputPayload>,
    ) => {
      const { hexId, searchInput } = action.payload;
      if (!state.dataframes[hexId]) {
        state.dataframes[hexId] = {
          selectedDataframe: undefined,
          searchInput,
          searchResultType: "objects",
          panelOpen: false,
          selectedColumnSearchResult: undefined,
        };
      } else {
        state.dataframes[hexId].searchInput = searchInput;
      }
    },
    setDataframeSearchResultType: (
      state,
      action: PayloadAction<SetDataframeSearchResultTypePayload>,
    ) => {
      const { hexId, resultType } = action.payload;
      state.dataframes[hexId].searchResultType = resultType;
    },
    setDataframePanelOpen: (
      state,
      action: PayloadAction<SetDataframePanelOpenPayload>,
    ) => {
      const { hexId, panelOpen } = action.payload;
      if (!state.dataframes[hexId]) {
        state.dataframes[hexId] = {
          selectedDataframe: undefined,
          searchInput: "",
          searchResultType: "objects",
          panelOpen: panelOpen,
          selectedColumnSearchResult: undefined,
        };
      } else {
        state.dataframes[hexId].panelOpen = panelOpen;
      }
    },
    setDataframeSelectedColumnSearchResult: (
      state,
      action: PayloadAction<SetDataframeSelectedColumnSearchResultPayload>,
    ) => {
      const { column, df, hexId } = action.payload;
      state.dataframes[hexId].selectedColumnSearchResult = { column, df };
    },
    setPanelHeight(state, action: PayloadAction<SetPanelHeightPayload>) {
      state.panelHeight = action.payload.panelHeight;
    },
    setAutoFocusSearch: (
      state,
      action: PayloadAction<SetAutoFocusSearchPayload>,
    ) => {
      const { autoFocusSearch, dataConnectionId } = action.payload;
      if (!state.dataConnections[dataConnectionId]) {
        state.dataConnections[dataConnectionId] = {
          browserNodes: [],
          searchNodes: [],
          selectedBrowserNode: undefined,
          selectedSearchNode: undefined,
          selectedColumnId: undefined,
          selectedColumnSearchResult: undefined,
          searchInput: "",
          searchActive: true,
          searchResultType: "objects",
          showPinnedOnly: false,
          panelOpen: false,
          dataLoaded: false,
          autoFocusSearch: true,
          nodesAutoExpanded: false,
        };
      } else {
        state.dataConnections[dataConnectionId].autoFocusSearch =
          autoFocusSearch;
      }
    },
    setInitialNodesRendered(state, action: PayloadAction<DataConnectionId>) {
      if (state.dataConnections[action.payload]) {
        state.dataConnections[action.payload].nodesAutoExpanded = true;
      }
    },
  },
});

export const schemaTreeBrowserReducer = schemaTreeBrowserSlice.reducer;

export const {
  clearSchemaBrowserState,
  clearSearch,
  collapseAllNodes,
  expandNodesAtPath,
  focusNode,
  resetSchemaBrowserState,
  setAutoFocusSearch,
  setChildren,
  setDataframePanelOpen,
  setDataframeSearchInput,
  setDataframeSearchResultType,
  setDataframeSelectedColumnSearchResult,
  setInitialNodesRendered,
  setNodeCategories,
  setNodeExpanded,
  setNodePinned,
  setNodeSelected,
  setNodeStatus,
  setNodesUnselected,
  setPanelHeight,
  setPanelOpen,
  setSearchActive,
  setSearchInput,
  setSearchResultType,
  setSearchResults,
  setSelectedColumnId,
  setSelectedColumnSearchResult,
  setSelectedDataframe,
  setShowPinnedOnly,
} = schemaTreeBrowserSlice.actions;
