import { AppSessionId, SavedViewId } from "@hex/common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store.js";

import {
  FilterCellSessionStateMP,
  ParameterValueMP,
} from "./appSessionMPSlice.js";

interface SavedViewDefaultAppSession {
  id: SavedViewId;
  name: string;
  shared: boolean;
  defaultAppSessionId: AppSessionId;
  parameterValues: ParameterValueMP[];
  filterCellSessionStates: FilterCellSessionStateMP[];
}

const savedViewDefaultAppSessionSlice = createSlice({
  name: "savedViewDefaultAppSession",
  initialState: null as SavedViewDefaultAppSession | null,
  reducers: {
    initializeSavedViewDefaultAppSession(
      _state,
      action: PayloadAction<SavedViewDefaultAppSession | null>,
    ) {
      return action.payload;
    },
  },
});

export const savedViewDefaultAppSessionSelectors = {
  selectParameterValues: (state: RootState) =>
    state.savedViewDefaultAppSession?.parameterValues ?? [],
  selectFilterCellSessionStates: (state: RootState) =>
    state.savedViewDefaultAppSession?.filterCellSessionStates ?? [],
  selectSavedViewId: (state: RootState) => state.savedViewDefaultAppSession?.id,
};

export const savedViewDefaultAppSessionActions = {
  ...savedViewDefaultAppSessionSlice.actions,
};

export const { initializeSavedViewDefaultAppSession } =
  savedViewDefaultAppSessionSlice.actions;

export const savedViewDefaultAppSessionReducer =
  savedViewDefaultAppSessionSlice.reducer;
