export type KeysOfUnion<T> = T extends T ? keyof T : never;

export function assertNonNull<T>(
  obj: T,
  msg: string,
): asserts obj is NonNullable<T> {
  if (obj == null) {
    throw new Error(msg);
  }
}
