import {
  EntityAdapter,
  EntityState,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";

import { RootState } from "../store";

/** Like `EntityAdapter.getSelectors`, except returns gracefully handles the underlying state not existing */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const getSelectorsForEntityState = <
  T,
  IdType extends string = T extends { id: string } ? T["id"] : string,
>(
  adapter: EntityAdapter<T>,
  stateGetter: (state: RootState) => EntityState<T> | null | undefined,
) => {
  const baseSelectors = adapter.getSelectors();

  // wraps a selector so that if the underlying state chunk doesn't exist, it just returns null.
  const selectorWrapper =
    <Args extends unknown[], R>(
      func: (state: EntityState<T>, ...args: Args) => R,
    ) =>
    (state: RootState, ...args: Args) => {
      const entityState = stateGetter(state);
      return entityState == null ? null : func(entityState, ...args);
    };

  const baseSelectById = selectorWrapper(baseSelectors.selectById);
  const baseSelectIds = selectorWrapper(baseSelectors.selectIds);

  return {
    selectAll: selectorWrapper(baseSelectors.selectAll),
    // manually type the arguments here so that we only accept the specific Id type of the given Entity.
    selectById: (state: RootState, id: IdType) => baseSelectById(state, id),
    selectIds: (state: RootState) => baseSelectIds(state) as IdType[],
    selectTotal: selectorWrapper(baseSelectors.selectTotal),
    selectEntities: selectorWrapper(baseSelectors.selectEntities),
  };
};

/** Like `getSelectorsForEntityState`, but the main selectors filter out soft-deleted elements. */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const getSelectorsForEntityStateWithSoftDelete = <
  T extends { deletedDate: string | null | undefined },
  IdType extends string = T extends { id: string } ? T["id"] : string,
>(
  adapter: EntityAdapter<T>,
  stateGetter: (state: RootState) => EntityState<T> | null | undefined,
) => {
  const baseSelectors = getSelectorsForEntityState<T, IdType>(
    adapter,
    stateGetter,
  );

  return {
    selectAll: createDraftSafeSelector(baseSelectors.selectAll, (entities) =>
      entities == null ? null : entities.filter((e) => e.deletedDate == null),
    ),
    selectById: (state: RootState, id: IdType) => {
      const entity = baseSelectors.selectById(state, id);
      return entity?.deletedDate != null ? null : entity;
    },
    selectEntities: createDraftSafeSelector(
      baseSelectors.selectEntities,
      (entities) =>
        entities == null
          ? null
          : Object.fromEntries(
              Object.entries(entities).filter(
                ([_, ele]) => ele && ele.deletedDate == null,
              ),
            ),
    ),
    selectAllWithDeleted: baseSelectors.selectAll,
    selectByIdWithDeleted: baseSelectors.selectById,
    selectEntitiesWithDeleted: baseSelectors.selectEntities,
    // we can implement automatic-filtering versions of these selectors as needed
    selectIdsWithDeleted: baseSelectors.selectIds,
    selectTotalWithDeleted: baseSelectors.selectTotal,
  };
};
