import {
  AtomicOperationResponse,
  BatchedAOResponses,
  GenericAtomicOperation,
} from "@hex/common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type MPDebuggerSliceState = {
  queuedAOBatches: BatchedAOResponses<GenericAtomicOperation>[];
  processedAtomicOperations: AtomicOperationResponse<GenericAtomicOperation>[];
  isStepThroughModeActive: boolean;
};
const initialState: MPDebuggerSliceState = {
  queuedAOBatches: [],
  processedAtomicOperations: [],
  isStepThroughModeActive: false,
};

type SetStepThroughMode = {
  isActive: boolean;
};

const mpDebuggerSlice = createSlice({
  name: "mpDebugger",
  initialState,
  reducers: {
    flushAll: (state) => {
      state.processedAtomicOperations = [];
      state.queuedAOBatches = [];
      state.isStepThroughModeActive = false;
    },
    flushAllProcessedOperations: (state) => {
      state.processedAtomicOperations = [];
    },
    markBatchAsProcessed: (
      state,
      ops: PayloadAction<BatchedAOResponses<GenericAtomicOperation>>,
    ) => {
      state.processedAtomicOperations = [
        // TODO - Maybe we want to reverse this for extra correctness?
        ...ops.payload.flat(),
        ...state.processedAtomicOperations,
      ];
    },
    queueBatch: (
      state,
      ops: PayloadAction<BatchedAOResponses<GenericAtomicOperation>>,
    ) => {
      // Because the AO controller has a list of readonly arrays, we need to map readonly array to be a mutable array
      state.queuedAOBatches.push(ops.payload.map((arr) => arr.map((op) => op)));
    },
    removeFirst: (state) => {
      state.queuedAOBatches = state.queuedAOBatches.slice(1);
    },
    processAllBatches: (state) => {
      state.processedAtomicOperations = state.queuedAOBatches.reduce(
        (acc, batch) => {
          acc = acc.concat(...batch.flat());
          return acc;
        },
        state.processedAtomicOperations,
      );
      state.queuedAOBatches = [];
    },
    processSingleBatch: (state) => {
      const batch = state.queuedAOBatches[0];
      if (batch) {
        state.processedAtomicOperations = [
          // TODO - Maybe we want to reverse this for extra correctness?
          ...batch.flat(),
          ...state.processedAtomicOperations,
        ];
        state.queuedAOBatches = state.queuedAOBatches.slice(1);
      }
    },
    setStepThroughMode: (state, op: PayloadAction<SetStepThroughMode>) => {
      state.queuedAOBatches = [];
      state.isStepThroughModeActive = op.payload.isActive;
    },
  },
});

export const mpDebuggerReducer = mpDebuggerSlice.reducer;
export const {
  flushAll,
  flushAllProcessedOperations,
  markBatchAsProcessed,
  processAllBatches,
  processSingleBatch,
  queueBatch,
  removeFirst,
  setStepThroughMode,
} = mpDebuggerSlice.actions;
