import {
  AnyAction,
  Store,
  StoreEnhancer,
  StoreEnhancerStoreCreator,
} from "@reduxjs/toolkit";
import { isEqual } from "lodash";

import { REDUX_POINTLESS_ACTION_LOGGING } from "./config";

/**
 * Gets a redux store enhancer that skips notifying subscribers
 * if a dispatched action doesn't cause any change in the redux store.
 * This is a performance optimization.
 */
export function getSubscribeOnChangeEnhancer(): StoreEnhancer {
  // This store enhancer works by overwriting the default `dispatch` and `subscribe` methods of the redux store.
  // Sadly, to do this, we have to duplicate a lot of redux's logic:
  // See https://github.com/reduxjs/redux/blob/a9181fc2f3230259dd36ba771bf6d2fb56081654/src/createStore.ts
  // Unfortunately, this is the "correct" way to make changes like this,
  // as the redux maintainers have rejected providing an easier way to do things.
  // See https://github.com/reduxjs/redux/pull/3836#issuecomment-668719187
  let currentListeners: (() => void)[] | null = [];
  let nextListeners = currentListeners;

  function ensureCanMutateNextListeners(): void {
    if (nextListeners === currentListeners) {
      nextListeners = currentListeners.slice();
    }
  }

  function subscribe(listener: () => void): () => void {
    if (typeof listener !== "function") {
      throw new Error("Expected listener to be a function.");
    }

    let isSubscribed = true;

    ensureCanMutateNextListeners();
    nextListeners.push(listener);

    return function unsubscribe() {
      if (!isSubscribed) {
        return;
      }

      isSubscribed = false;

      ensureCanMutateNextListeners();
      const index = nextListeners.indexOf(listener);
      nextListeners.splice(index, 1);
    };
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  // If you can figure out how to get all the types correct here, I bow to you
  return (next: StoreEnhancerStoreCreator): StoreEnhancerStoreCreator =>
    (reducer: any, preloadedState?: any) => {
      const store = next(reducer, preloadedState);

      // we make our own `dispatch` function that notifies subscribers
      // only if a state change has occurred.
      function dispatch<T extends AnyAction>(action: T): T {
        const prevState = store.getState();
        // run the real `dispatch` function which runs all the reducers and such
        const res = store.dispatch(action);
        const newState = store.getState();

        const shouldLog =
          process.env.NODE_ENV === "development" &&
          REDUX_POINTLESS_ACTION_LOGGING;

        if (shouldLog) {
          if (prevState === newState) {
            // eslint-disable-next-line no-console
            console.log("skipping action with no state change", action.type);
          } else if (isEqual(prevState, newState)) {
            // eslint-disable-next-line no-console
            console.log(
              "action produced referentially different but equal value-wise state." +
                " This is bad because all selectors will still run.",
              action.type,
            );
          }
        }

        // this is the core of our change, we exit early if there's been no overall state change
        if (prevState === newState) {
          return res;
        }

        const listeners = (currentListeners = nextListeners);
        for (let i = 0; i < listeners.length; i++) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          listeners[i]!();
        }

        return res;
      }

      // Finally, replace the original store's `dispatch` and `subscribe` functions with our own version
      const newStore: Store<any, any> = {
        ...store,
        dispatch: dispatch as any,
        subscribe,
      };

      return newStore;
    };
  /* eslint-enable @typescript-eslint/no-explicit-any */
}
