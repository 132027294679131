import { MapLayer } from "@hex/common";
import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";

import { MapDatasetStatus } from "../../components/deckgl/dataset/MapDatasetTypes";
import { Feature } from "../../components/deckgl/geojson/geojsonTypes";
import { RootState } from "../store";

// keep statuses and datasets separate so that updating statuses
// does not trigger expensive recomputations in useMapComputedData
type MapDatasetSliceState = {
  statuses: {
    [dataset: string]: MapDatasetStatus;
  };
  datasets: {
    [dataset: string]: readonly Feature[];
  };
};
const initialState: MapDatasetSliceState = {
  statuses: {},
  datasets: {},
};

/**
 * Map datasets can be large and we want to load any dataset
 * once per session, instead of per layer or even per cell.
 * Use redux and saga (mapDatasetSaga) to keep the datasets
 * loaded and in sync with map layers
 */
const mapDatasetSlice = createSlice({
  name: "mapDataset",
  initialState,
  reducers: {
    setDatasetStarted: (state, action: PayloadAction<{ dataset: string }>) => {
      state.statuses[action.payload.dataset] = {
        type: "started",
      };
    },
    setDatasetHeader: (
      state,
      action: PayloadAction<{
        dataset: string;
        status: number;
        sizeInBytes: number;
      }>,
    ) => {
      const { dataset, sizeInBytes, status } = action.payload;
      state.statuses[dataset] = {
        type: "header",
        status,
        sizeInBytes,
      };
    },
    setDatasetStreaming: (
      state,
      action: PayloadAction<{
        dataset: string;
        completionRate: number;
      }>,
    ) => {
      const { completionRate, dataset } = action.payload;
      state.statuses[dataset] = {
        type: "streaming",
        completionRate,
      };
    },
    setDatasetLoaded: (
      state,
      action: PayloadAction<{
        dataset: string;
        data: readonly Feature[];
      }>,
    ) => {
      const { data, dataset } = action.payload;
      state.datasets[dataset] = data as Feature[]; // redux types requires non-readonly array
      state.statuses[dataset] = {
        type: "loaded",
      };
    },
    setDatasetFailed: (
      state,
      action: PayloadAction<{
        dataset: string;
        error: Error;
      }>,
    ) => {
      const { dataset, error } = action.payload;
      state.statuses[dataset] = {
        type: "failed",
        error,
      };
    },
    removeDataset: (
      state,
      action: PayloadAction<{
        dataset: string;
      }>,
    ) => {
      const { dataset } = action.payload;
      delete state.statuses[dataset];
    },
  },
});

export const mapDatasetReducer = mapDatasetSlice.reducer;
export const mapDatasetInternalActions = mapDatasetSlice.actions;

export const mapDatasetActions = {
  updateDatasets: createAction<{ layers: readonly MapLayer[] }>(
    "mapDataset/updateDatasets",
  ),
};

export const selectDatasetStatuses = (
  state: RootState,
): Record<string, MapDatasetStatus> => state.mapDataset.statuses;

export const selectLoadedDatasets = (
  state: RootState,
): Record<string, readonly Feature[]> => state.mapDataset.datasets;
